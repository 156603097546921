 <footer id="footer" class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-8">
		 	 <a href="index.html"><img src="assets/images/logonew1.png" alt="Hayansh Organic is a pharma organization based out at Hyderabad, India."></a>
            <h6>HAYANSH ORGANIC PVT LTD</h6>
			<ul class="footer-links">
              <li><i class ="fa fa-envelope" style="font-size:20px;"> hayanshpharma@gmail.com </i></li><br/>
			  <li><i class="fas fa-globe" style="font-size:20px;">  HNO 8-415/20 SAPTHAGIRI COLONY Miyapur, Hyderabad-500084, India</i></li>
			  <br/>
              <li><i class ="fa fa-phone" style="font-size:20px;">  +91-8455293115</i></li>         
            </ul>
			</div>
			        

          <div class="col-xs-6 col-md-4">
            <h6>Quick Links</h6>
            <ul class="footer-links">
			        <li><a href="#">Home</a></li>
              <li><a href="#service">Services</a></li>
              
			        <li><a href="../assets/files/HayanshOrganicProductList.pdf" target="_blank">Products</a></li>
              
			        <li><a href="#work">Our Facility</a></li>
              <li><a href="#about">About Us</a></li>
              <li><a href="#contact">Contact Us</a></li>              
            </ul>
          </div>
        </div>
        <hr>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6 col-xs-12">
            <p class="copyright-text">© Copyright 2021. All Rights Reserved.. 
         <a href="#">HAYANSH ORGANIC PVT LTD</a>.
            </p>
          </div>

          <div class="col-md-4 col-sm-6 col-xs-12">
            <ul class="social-icons">
              <li><a class="facebook" href="#"><i class="fab fa-facebook"></i></a></li>
              <li><a class="twitter" href="#"><i class="fab fa-twitter"></i></a></li>
              <li><a class="dribbble" href="#"><i class="fab fa-dribbble"></i></a></li>
              <li><a class="linkedin" href="#"><i class="fab fa-linkedin"></i></a></li>   
            </ul>
          </div>
        </div>
      </div>
</footer>