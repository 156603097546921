import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

 

	
	
	form: FormGroup;
	name: FormControl = new FormControl("", [Validators.required]);
	email: FormControl = new FormControl("", [Validators.required, Validators.email]);
	message: FormControl = new FormControl("", [Validators.required, Validators.maxLength(256)]);
	subject: FormControl = new FormControl("", [Validators.required, Validators.maxLength(256)]);
	phone: FormControl = new FormControl("", [Validators.required, Validators.maxLength(256)]);
	//honeypot: FormControl = new FormControl(""); // we will use this to prevent spam
	public submitted: boolean = false; // show and hide the success message
	public isLoading: boolean = false; // disable the submit button if we're loading
	responseMessage: FormControl = new FormControl("");

	constructor(private formBuilder: FormBuilder, private http: HttpClient) {
		this.form = this.formBuilder.group({
			name: this.name,
			email: this.email,
			message: this.message,
			subject: this.subject,
			phone: this.phone
			//honeypot: this.honeypot,
			//responseMessage: this.responseMessage

		});

	}
	ngOnInit(): void {
	}
	onSubmit() {

		var data = "** RECEIVED A MAIL FROM CUSTOMER FOR CONTACT BELOW ARE DETAILS ** \n name = {" + this.name.value + 
				"}\n subject = {" + this.subject.value+  "} \n from email = {" + this.email.value + "}"+
				"\n Customers message = {"+ this.message.value +"} \n Customer Phone Number {" +this.phone.value + "}";
			
		if (this.form.status == "VALID" ) {
			this.form.disable();
			this.isLoading = true; // sending the post request async so it's in progress
			this.submitted = false; // hide the response message on multiple submits
			var xhr = new XMLHttpRequest();
			xhr.open('POST', "https://script.google.com/macros/s/AKfycbxag1m997lqQB6Y9aVc7S-4CUxxcGI_0Z6YMLjXdOfpFx3tvRGfJ8aN-m8THOdHvnPw/exec");
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
			
			xhr.onreadystatechange = function () {
				if (xhr.status === 200) {
					//console.log(xhr.responseText);
					return;
				}
				else {
					console.log('Request failed. Returned status: ' + xhr.status);
          
					return;
				}
			}
			xhr.send(data);
			this.form.enable(); // re enable the form after a success
          	this.submitted = true; // show the response message
          	this.isLoading = false;
			this.responseMessage.setValue("Thanks for the Contacting US! We'll get back to you soon!"); 
			console.log("Mail send successfully");
			//this.form.reset();
			
			
		} else {
			this.responseMessage.setValue("Please enter valid details.");
			
          this.form.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          this.isLoading = false; // re enable the submit button
		  console.log("Data entered by customer is not valid in form.");
		}
		
		
		
	}

}
