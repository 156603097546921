<!-- ======= Services Section ======= -->
<div id="home" class="intro route bg-image" style="background-image: url(assets/img/teamworkpsv1.jpg)">
   
</div>

<section id="service" class="services-mf pt-5 route">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="title-box text-center pt-5">
                    <h3 class="title-a text-light">
                        Services
                    </h3>
                    <p class="subtitle-a text-light">
                        These are some services we can help you with
                    </p>
                    <div class="line-mf"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-capsules"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title">Medicinal Chemistry</h2>
                        <p class="s-description text-center">
                            Hit Identification, solve and Lead Optimization
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-diagnoses"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title">Custom Synthesis</h2>
                        <p class="s-description text-center">
                             Building Blocks, Reference Standards, and Impurity. <br/>
                             Develop alternate Routes of Synthesis (ROS) with a techno-commercial evaluation. <br/>
                             
												
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-hand-holding-medical"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title">Process Chemistry</h2>
                        <p class="s-description text-center">
                            Process R&D and develop up to scale-up batches <br/>
                            Provides a cost-effective & innovative approach
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-file-medical"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title">Speciality Chemistry</h2>
                        <p class="s-description text-center">
                            Nitro, Amino, Chloro and Bromo compounds <br/>
                            Oligosaccharides, Enzymatic and many more
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="ion-stats-bars"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title">Marketing Services</h2>
                        <p class="s-description text-center">
                            Inbound Marketing advertising and commercial strategies for automated sales processes.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-star-of-life"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title">Customer  Services</h2>
                        <p class="s-description text-center">
                            Closely work with our clients for understanding their requirements. <br/>
                            Customer-Centric business model. <br/>
                            Customized to client’s requirements.
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section><!-- End Services Section -->