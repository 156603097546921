<!-- ======= Intro Section ======= -->
<!-- End Intro Section -->

<main id="main">

    <!-- ======= About Section ======= -->
    <section id="about" class="about-mf sect-pt4 route">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="box-shadow-full">
                        <div class="row">
                            <div class="col-md-15">
                                <div class="about-me pt-5 pt-md-0">
                                    <div class="title-box-2">
                                        <h5 class="title-left">
                                            About Us
                                        </h5>
                                    </div>
                                    <span>
                                        <p class="lead">
                                        Hayansh Organic Pvt Ltd,a renowned name in the pharmaceutical sector,has built a high
                                            reputation as a complete solution provider in the pharmaceutical arena.Our organizationis
                                            leading manufacturer and supplier of Active Pharmaceutical Ingredients and Drug Intermediates.
                                            Our expertise in this area has made us one of the most trusted companies in the industry.The
                                            company is scaling new heights of success under the leading lights of highly skilled
                                            professionals. Our dedicated compliant R&D facility shelters highly advanced equipment and
                                            technology that compares to the leading pharmaceutical companies of the country.
                                    </p>
                                    </span>
                                    <h5 class="title-left">
                                           Our Mission
                                        </h5>
                                    <br/>
                                    <p class="lead">
                                        
                                        To become a world-class pharmaceutical company by serving the global pharmaceutical
                                                industry through innovative, integrity,quality and affordability.
                                    </p>
                                    <h5 class="title-left">
                                           Our Vision
                                        </h5>
                                    <br/>
                                    <p class="lead">
                                        
                                        To be the industry standard for quality pharmaceuticals backed by powerful research and innovation.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section><!-- End About Section -->
</main>