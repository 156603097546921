<!-- ======= Portfolio Section ======= -->
<section id="work" class="portfolio-mf sect-pt4 pb-5 route">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="title-box text-center pt-5">
                    <h3 class="title-a">
                        OUR FACILITY
                    </h3>
                    <p class="subtitle-a">
                        Our Facility contains below equipment
                    </p>
                    <div class="line-mf"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div  class="col-md-4">
                <div class="service-box">
                     <div class="service-ico">
                        <span class="ico-circle"><i class="fab fa-servicestack"></i></span>
                    </div>
                    <h2 class="w-title">Custom services and production, pilot plant</h2>
                </div>                
            </div>
            <div  class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fab fa-houzz"></i></span>
                    </div>
                    <h2 class="w-title">Pilot plant reactor scale range 100L to 500L SSR and glass lined</h2>
                </div>                
            </div>
            <div  class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-fill-drip"></i></span>
                    </div>
                
                    <h2 class="w-title">Scale up facility 5KL – 10KL SSR and Glass Reactors.</h2>
                </div>                
            </div>
             <div  class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-warehouse"></i></span>
                    </div>
                    <h2 class="w-title">Drying, milling, micronization and packaging facility</h2>
                </div>                
            </div>
             <div  class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-balance-scale"></i></span>
                    </div>
                    <h2 class="w-title">Well-equipped QC laboratories</h2>
                </div>                
            </div>

        </div>
    </div>
     <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="title-box text-center pt-5">
                    <h3 class="title-a">
                        WHY PREFER US
                    </h3>
                   
                    <div class="line-mf"></div>
                </div>
            </div>
        </div>
        <div class="row">
             <div class="col-md-4">
                <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="ion-cash"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title">Essential Investment Characteristic</h2>
                        <p class="s-description text-center">
                            Strong competitive position. <br/>
                            Prospective cost-saving movements. <br/>
                            Small molecule contract manufacturing organization (CMO).
                        </p>
                    </div>
                </div>
               
            </div>
            <div class="col-md-4">
                 <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="fas fa-handshake"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title">People Factor</h2>
                        <p class="s-description text-center">
                            Long-time management team. <br/>
                            Superior research team having broad spectrum of expertise with process development.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                 <div class="service-box">
                    <div class="service-ico">
                        <span class="ico-circle"><i class="ion-wrench"></i></span>
                    </div>
                    <div class="service-content">
                        <h2 class="s-title">Functional Factor</h2>
                        <p class="s-description text-center">
                            Superior R&D by using four pillar model (people, process, technology and infrastructure). <br/>
                            Very Strong prospective cost-saving improvements within the short time. <br/>
                            Promising products in timeline. <br/>
                            Extensive financial resources.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section><!-- End Portfolio Section -->