<!-- ======= Header/ Navbar ======= -->
<nav class="navbar navbar-b navbar-trans navbar-expand-md fixed-top" id="mainNav">
    <div class="container">
        <a href="index.html"><img src="assets/images/logonew1.png" ></a>
        <a class="navbar-brand js-scroll" href="#page-top">HAYANSH ORGANIC Pvt Ltd</a>
        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault"
            aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span></span>
            <span></span>
            <span></span>
        </button>
        <div class="navbar-collapse collapse justify-content-end" id="navbarDefault">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link js-scroll active" href="#">Home</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link js-scroll" href="#service">Services</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link js-scroll" a href="../assets/files/HayanshOrganicProductList.pdf" target="icon_blank">Products</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link js-scroll" href="#work">OUR FACILITY</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link js-scroll" href="#about">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link js-scroll" href="#contact">Contact Us</a>
                </li>
            </ul>
        </div>
    </div>
</nav>