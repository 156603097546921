
 
 
 <section id="contact" class="contact-page-section">
      <div class="container">
          <div class="sec-title">
              <div class="title">Contact Us</div>
                <h2>Let's Get in Touch.</h2>
            </div>
            <div class="inner-container">
              <div class="row clearfix">
                
                  <!--Form Column-->
                    <div class="form-column col-md-8 col-sm-12 col-xs-12">
                      <div class="inner-column">
                          
                            <!--Contact Form-->
                            <div class="contact-form">
                                <form class="contact"  [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
                                    <div class="row clearfix">
                                        <div class="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input type="text" [formControl]="name" name="name" placeholder="Name" required>
                                            <div *ngIf="name.invalid">Please enter your name</div>
                                        </div>
                                        <div class="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input type="email" [formControl]="email" name="email" placeholder="Email" required>
                                        </div>
                                        <div class="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input type="text" [formControl]="subject" name="subject" placeholder="Subject" required>
                                        </div>
                                        <div class="form-group col-md-6 col-sm-6 co-xs-12">
                                            <input type="text" [formControl]="phone" name="phone" placeholder="Phone" required>
                                        </div>
                                        <div class="form-group col-md-12 col-sm-12 co-xs-12">
                                            <textarea [formControl]="message"  name="message" placeholder="Massage"></textarea>
                                        </div>
                                        <div class="form-group col-md-12 col-sm-12 co-xs-12">
                                            <button [disabled]="isLoading" type="submit" class="theme-btn btn-style-one">Submit</button>             
                                        </div>
                                        <div class="form-group col-md-12 col-sm-6 co-xs-12">
                                            <div class="w-1" *ngIf="submitted">
                                                <textarea rows="2" font-size="2px">{{responseMessage.value}}</textarea> 
                                            </div>
                                        </div>
                                       
                                    </div>
                                </form>
                            </div>
                            <!--End Contact Form-->
                            
                        </div>
                    </div>
                    
                    <!--Info Column-->
                    <div class="info-column col-md-4 col-sm-12 col-xs-12">
                      <div class="inner-column">
                          <h2>Contact Info</h2>
                            <ul class="list-info">
                              <li><i class="fas fa-globe"></i>HNO 8-415/20 SAPTHAGIRI COLONY Miyapur, Hyderabad-500084, India</li>
                                <li><i class="far fa-envelope"></i> hayanshpharma@gmail.com</li>
                                <li><i class="fas fa-phone"></i>+91-8455293115</li>
                            </ul>
                            <ul class="social-icon-four">
                                <li class="follow">Follow on: </li>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-dribbble"></i></a></li>
                                <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </section>